import React from "react"
import { Link } from "gatsby"

const TooltipDoctors = () => {
  return (
    <div className="tooltip-menu tooltiptext">
      <div id="triangle-up"></div>
      <div className="tooltip-nav">
        <ul>
          <li>
            <Link to="/doctors/dr-michael-detora" style={{ textTransform: "none" }}>
              Dr. Michael DeTora DVM DACVS
            </Link>
            <Link to="/doctors/dr-todd-riecks" style={{ textTransform: "none" }}>
              Dr. Todd Riecks DVM DACVS
            </Link>
            <Link to="/doctors/dr-scot-swainson" style={{ textTransform: "none" }}>
              Dr. Scot Swainson DVM DACVS
            </Link>
            <Link to="/doctors/dr-andra-detora" style={{ textTransform: "none" }}>
              Dr. Andra DeTora DVM CCRT cVMA
            </Link>
            <Link to="/doctors/dr-brian-dent" style={{ textTransform: "none" }}>
              Dr. Brian Dent DVM DACVS
            </Link>
            <Link to="/doctors/dr-leah-hixon" style={{ textTransform: "none" }}>
              Dr. Leah Hixon DVM DACVS
            </Link>
            <Link to="/doctors/dr-lindsay-brooks" style={{ textTransform: "none" }}>
              Dr. Lindsay Brooks (Peterson) DVM DACVS
            </Link>
            <Link to="/doctors/dr-nic-cabano" style={{ textTransform: "none" }}>
              Dr. Nic Cabano DVM DACVS DACVSMR
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
export default TooltipDoctors
